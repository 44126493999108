<template>
  <a-layout-sider width="160" style="background: #fff">
    <a-spin :spinning="loading">
      <a-menu v-model:selectedKeys="selectedKeys" style="height: 100%">
        <a-affix :offset-top="64">
          <a-menu-item key="collectChart" @click="handleToPage('/user/home')">
            <span>收藏图表</span>
          </a-menu-item>
          <a-menu-item key="userInfo" @click="handleToPage('/user/info')">
            <span>个人资料</span>
          </a-menu-item>
          <a-menu-item v-for="item in authorities.authMenuList" :key="item.authMenu.menuCode" @click="handleToPage('/user/' + item.authMenu.menuCode)">
            <span>{{ item.authMenu.menuName }}</span>
          </a-menu-item>
        </a-affix>
      </a-menu>
    </a-spin>
  </a-layout-sider>
</template>

<script>
import { getUserAuthorities } from "../api/user"

export default {
  props: {
    keys: {
      default: ['userInfo']
    }
  },
  data() {
    return {
      selectedKeys: this.keys,
      loading: false,
      authorities: {
        authMenuList: [],
        authOperationList: []
      }
    }
  },
  created() {
    this.getUserAuth()
  },
  methods: {
    getUserAuth() {
      this.loading = true
      getUserAuthorities().then(res => {
        this.authorities = res.data
        this.loading = false
      })
    },
    handleToPage(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
