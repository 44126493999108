import request from "../plugins/request";

// 获取用户网址列表
export function getUserWebsites(params) {
    return request({
        url: '/user/website/list',
        method: 'get',
        params: params
    })
}

// 网址跳转
export function websiteRedirect(params) {
    return request({
        url: '/user/website/redirect',
        method: 'get',
        params: params
    })
}

// 获取用户网址分类
export function getUserWebsiteTypes() {
    return request({
        url: '/user/website/types',
        method: 'get'
    })
}
