<template>
  <a-layout>
    <Header />
    <a-layout-content :style="{ padding: '16px', marginTop: '64px' }">
      <a-layout style="padding: 16px 0; background: #fff">
        <UserHomeSider :keys="['website']" />
        <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
          <a-affix :offset-top="64">
            <a-card size="small" style="background: #f5f5f5; margin-bottom: 16px;">
              <a-radio-group v-model:value="size" @change="onChange">
                <a-radio-button value="0">全部</a-radio-button>
                <a-radio-button v-for="item in types" :key="item.websiteTypeId" :value="item.websiteTypeId">{{ item.typeName }}</a-radio-button>
              </a-radio-group>
            </a-card>
          </a-affix>
          <a-list :grid="{ gutter: 12, column: 6 }" :data-source="list" :loading="loading">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-card hoverable size="small" style="height: 72px;" @click="goWebsiteUrl(item.websiteId)">
                  <a-card-meta>
                    <template #title>
                      <span>{{ item.title }}</span>
                    </template>
                    <template #avatar>
                      <a-avatar :src="'https://static.muzimuzi.com/' + item.thumb" />
                    </template>
                    <template #description>
                      <span style="font-size: 12px; white-space: nowrap;">{{ item.description }}</span>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-layout-content>
      </a-layout>
    </a-layout-content>
    <Footer />
  </a-layout>
</template>

<script>
import {getUserWebsites, websiteRedirect, getUserWebsiteTypes} from "../../api/website"
import Header from '../../components/header'
import Footer from '../../components/footer'
import UserHomeSider from '../../components/userHomeSider'

export default {
  components: { Header, Footer, UserHomeSider },
  data() {
    return {
      size: '0',
      types: [],
      loading: false,
      typeId: 0,
      list: []
    }
  },
  created() {
    this.getUserWebsiteList();
    this.getUserWebsiteTypeList();
  },
  methods: {
    // 获取网址类别
    getUserWebsiteTypeList() {
      getUserWebsiteTypes().then(res => {
        this.types = res.data
      })
    },
    // 获取网址列表
    getUserWebsiteList() {
      this.loading = true
      getUserWebsites({ typeId: this.typeId}).then(res => {
        this.list = res.data
        this.loading = false
      })
    },
    // 网址url跳转
    goWebsiteUrl(websiteId) {
      websiteRedirect({ websiteId: websiteId}).then(res => {
        window.open(res.data)
      })
    },
    // 切换类别
    onChange(e) {
      this.typeId = e.target.value
      this.getUserWebsiteList()
    }
  }
}
</script>

<style scoped>
.ant-card-actions li{
  margin: 0 auto;
}
</style>
